// @ts-nocheck

import { registerApplication, start } from 'single-spa'
import './styles.css'

registerApplication({
  name: '@germinare/navbar',
  app: () => System.import('@germinare/navbar'),
  activeWhen: ['/'],
})

start({
  urlRerouteOnly: true,
})
